export const ITEMS = [
  "account.id",
  "account.accountName",
  "store.channel",
  "store.countryCode",
  "store.cultureInfo",
  "store.currencyCode",
  "store.currencySymbol",
  "store.admin_cultureInfo",
  "creditControl.creditAccounts",
  "creditControl.deadlines",
  "creditControl.minimumInstallmentValue",
  "authentication.storeUserId",
  "authentication.storeUserEmail",
  "profile.firstName",
  "profile.document",
  "profile.email",
  "profile.id",
  "profile.isAuthenticated",
  "profile.lastName",
  "profile.phone",
  "public.favoritePickup",
  "public.utm_source",
  "public.utm_medium",
  "public.utm_campaign",
  "public.utmi_cp",
  "public.utmi_p",
  "public.utmi_pc",
];
